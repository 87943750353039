import {connectHits} from "instantsearch.js/es/connectors";
import {languageCode} from "./variables.js";

const renderHits = (renderOptions, isFirstRender) => {
    const {hits, results, sendEvent, widgetParams} = renderOptions;

    if (isFirstRender) {
        return;
    }

    const SearchResults = document.getElementById("alg-search-result");
    SearchResults.textContent = `About ${results.nbHits} result (${results.processingTimeMS} seconds)`;

    widgetParams.container.innerHTML = `
        ${hits
        .map(
            (item) =>
                `
            <div class="col-sm-6 col-lg-3">
                <div class="card card-sm">
                  <a href="${languageCode + item.url}" class="d-block listing-redirect" data-listing-slug="${item.objectID}">
                    <div class="img-responsive img-responsive-1x1 rounded border" 
                    style="background-image: url(${item.image_url ? item.image_url : "https://images.unsplash.com/photo-1462826303086-329426d1aef5?q=80&w=3570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"})"
                    ></div>
                  </a>
                  <div class="card-body">
                    <div class="d-flex align-items-center overflow-auto">
                      <div>
                        <div class="single-line-text">${item.title}</div>
    <!--                                <div class="text-muted">Country: ${item.country}</div>-->
                        <div class="text-muted">
                            Price: ${item.currency} ${item.price.toLocaleString("en", {maximumFractionDigits: 0})}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            `
        )
        .join("")}
    `;
};

const customHits = connectHits(renderHits);

export const searchPageHits = customHits({
    container: document.querySelector("#hits-search-page"),
});
