import {connectSortBy} from 'instantsearch.js/es/connectors';

const renderSortBy = (renderOptions, isFirstRender) => {
    const {
        options,
        currentRefinement,
        canRefine,
        refine,
        widgetParams,
    } = renderOptions;

    if (isFirstRender) {
        const divEl = document.createElement('div');

        divEl.addEventListener('change', event => {
            refine(event.target.value);
        });

        widgetParams.container.appendChild(divEl);
    }

    const divEl = widgetParams.container.querySelector('div');

    divEl.disabled = !canRefine;

    divEl.innerHTML = `
        <div>
            ${options
        .map(
            option => `
          <label class="form-check">
            <input class="form-check-input" type="radio" value="${option.value}" name="radios" ${option.value === currentRefinement ? 'checked' : ''}>
            <span class="form-check-label">${option.label}</span>
          </label>
        `
        )
        .join('')}
        </div>
    `;
};

const customSortBy = connectSortBy(renderSortBy);

export const sortByPriceWidget = customSortBy({
    container: document.querySelector('#sort-by-price'),
    items: [
        {label: 'Default', value: algoliaIndexName},
        {label: 'Decrease', value: `${algoliaIndexName}_price_desc`},
        {label: 'Increase', value: `${algoliaIndexName}_price_asc`},
    ],
});